import { startTransition, StrictMode, useEffect } from 'react'
import { hydrateRoot } from 'react-dom/client'
import createEmotionCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import { RemixBrowser, useLocation, useMatches } from '@remix-run/react'
import * as Sentry from '@sentry/remix'

/* Sentry SDKの初期化コード */
// https://sentry.io
Sentry.init({
  // 環境の識別、sentry.ioでのエラー確認で使用する
  environment: process.env.NODE_ENV,
  enabled: process.env.NODE_ENV === 'production',

  // sentry.ioのプロジェクト識別子
  dsn: 'https://096aab97680c0c38e5ab01e26217d3e9@o4507163208056832.ingest.us.sentry.io/4507163267694592',
  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    Sentry.replayIntegration(),
  ],
  // パフォーマンス計測データの収集レベル(1 = 100%)
  // Sentry.ioはproduction buildではこの値を調整することを推奨している
  tracesSampleRate: 1,

  // LambdaによるAPIなど、分散系アプリケーションの場合に使用するオプション
  // モノリシックな構成の場合は無効化でOK
  tracePropagationTargets: [],

  // 通常のリプレイ収集(10%が上限とのこと)
  replaysSessionSampleRate: 0.1,

  // エラーのリプレイ収集レベル(1 = 100%)
  replaysOnErrorSampleRate: 1,
})

const hydrate = () => {
  const emotionCache = createEmotionCache({ key: 'css' })

  startTransition(() => {
    hydrateRoot(
      document,
      <StrictMode>
        <CacheProvider value={emotionCache}>
          <RemixBrowser />
        </CacheProvider>
      </StrictMode>,
    )
  })
}

if (typeof requestIdleCallback === 'function') {
  requestIdleCallback(hydrate)
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  setTimeout(hydrate, 1)
}
